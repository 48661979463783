'use client';

import { Paper } from '@mui/material';

import { Brand, FlexBox } from '../atoms';

export function Loading() {
  return (
    <Paper sx={{ borderRadius: 0, height: 60, position: 'fixed', width: '100%' }}>
      <FlexBox sx={{ alignItems: 'center', height: '100%', p: 2 }}>
        <Brand breakpoint="md" height={20} scale={2 / 3} />
      </FlexBox>
    </Paper>
  );
}
